/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import DebitCard from "../../debitCard/DebitCard";
import { countryList } from "../../constants/countries";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { invoicePaymentSchema } from "./helper";
import { errorNotification } from "../../notification-popup";
import { useSearchParams } from "react-router-dom";
import { api_checkout_invoice_url } from "../constants";
import "../checkout/style.css";
import { GooglePay } from "../google-pay";
import { ApplePay } from "../apple-pay";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import debit from "../../assets/svgs/debit-icon.svg";
import credit from "../../assets/svgs/credit-icon.svg";
import moment from "moment";

const Invoice = () => {
  const [focused, setFocused] = useState("");
  const [featureData, setFeatureData] = useState({
    amount: "",
    currency: "",
    is_apple_pay: false,
    is_google_pay: false,
    is_support_3d: false,
    customer_name: "",
    phone_number: "",
    customer_email: "",
    customer_address: "",
    expiry_time: "",
    merchant_invoice_id: "",
    merchant_site_name: "",
    merchant_name: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isGooglePaying, setIsGooglePaying] = useState(false);
  const [isApplePaying, setIsApplePaying] = useState(false);
  const [searchParams] = useSearchParams();

  const [mode, setMode] = useState("debit");
  const [showMerchantDetails, setShowMerchantDetails] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(invoicePaymentSchema),
    defaultValues: {
      cardname: "",
      cardnumber: "",
      month: "",
      year: "",
      cvv: "",
      is_google_pay: false,
      is_apple_pay: false,
    },
  });

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleFocused = (e) => {
    setFocused(e.target.name === "cvv" ? "cvc" : e.target.name);
  };

  // Handle input change
  const handleChange = (e, name, limit) => {
    const value = e.target.value;
    const sanitizedValue = value.replace(/[^\d]/g, "");
    if (
      (name === "month" || name === "year") &&
      sanitizedValue.length <= limit
    ) {
      e.target.value = sanitizedValue;
      setValue(name, value);
      return;
    }
    if (name === "cvc" && sanitizedValue.length <= limit) {
      e.target.value = sanitizedValue;
      setValue("cvv", value);
      return;
    } else {
      e.target.value = sanitizedValue.slice(0, limit);
    }
  };

  const onSubmit = async (values) => {
    console.log(values);
    if (!isSubmitting) {
      if (!executeRecaptcha) {
        errorNotification("reCAPTCHA not initialized");
        return;
      }

      const captchaToken = await executeRecaptcha("submit");

      if (!captchaToken) {
        errorNotification("Failed to get reCAPTCHA token");
        return;
      }

      delete values["is_apple_pay"];
      delete values["is_google_pay"];
      const apiUrl = api_checkout_invoice_url + "wpf/invoice";
      setIsSubmitting(true);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          ...values,
          captchaValue: captchaToken,
          i2n8e34v: searchParams.get("i2n8e34v"),
          payment_method: "card",
          is_support_3d: featureData.is_support_3d,
        }),
      };

      try {
        const response = await fetch(apiUrl, requestOptions);
        const data = await response.json();
        if (!response.ok) {
          setIsSubmitting(false);
          errorNotification(data?.message);
          throw new Error(response);
        }
        const d = data?.data || data;
        window.location.href = d.url;

        setIsSubmitting(false);
      } catch (error) {
        setIsSubmitting(false);
      }
    }
  };

  const handleCheckFeature = async () => {
    const apiUrl = api_checkout_invoice_url + "wpf/check-feature";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        i2n8e34v: searchParams.get("i2n8e34v"),
      }),
    };

    try {
      const response = await fetch(apiUrl, requestOptions);
      const data = await response.json();
      if (!response.ok) {
        throw new Error(response);
      }
      setFeatureData(data.data);
      console.log(data.data);

      setValue("email", data.data.customer_email);
      setValue("phone", data.data.phone_number);
      setValue("address", data.data.customer_address);
      setValue("currency", data.data.currency);
    } catch (error) {}
  };

  useEffect(() => {
    handleCheckFeature();
  }, []);

  const handleGooglePay = async (isCallSubmit, body) => {
    setValue("is_google_pay", true);
    setValue("is_apple_pay", false);
    if (isCallSubmit) {
      handleSubmit(onSubmit)(); // Trigger form submission programmatically
    } else {
      if (!executeRecaptcha) {
        errorNotification("reCAPTCHA not initialized");
        return;
      }

      const captchaToken = await executeRecaptcha("submit");

      if (!captchaToken) {
        errorNotification("Failed to get reCAPTCHA token");
        return;
      }
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          ...body,
          captchaValue: captchaToken,
          i2n8e34v: searchParams.get("i2n8e34v"),
          is_support_3d: featureData.is_support_3d,
        }),
      };
      const apiUrl = api_checkout_invoice_url + "wpf/invoice";
      try {
        const response = await fetch(apiUrl, requestOptions);
        const data = await response.json();
        if (!response.ok) {
          setIsGooglePaying(false);
          errorNotification(data?.message);
          throw new Error(response);
        }
        const d = data?.data || data;
        window.location.href = d.url;

        setIsGooglePaying(false);
      } catch (error) {
        setIsGooglePaying(false);
      }
    }
  };

  const handleApplePay = async (isCallSubmit, body) => {
    setValue("is_google_pay", false);
    setValue("is_apple_pay", true);
    if (isCallSubmit) {
      handleSubmit(onSubmit)(); // Trigger form submission programmatically
    } else {
      if (!executeRecaptcha) {
        errorNotification("reCAPTCHA not initialized");
        return;
      }

      const captchaToken = await executeRecaptcha("submit");

      if (!captchaToken) {
        errorNotification("Failed to get reCAPTCHA token");
        return;
      }
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          ...body,
          captchaValue: captchaToken,
          i2n8e34v: searchParams.get("i2n8e34v"),
          is_support_3d: featureData.is_support_3d,
        }),
      };
      const apiUrl = api_checkout_invoice_url + "wpf/invoice";
      try {
        const response = await fetch(apiUrl, requestOptions);
        const data = await response.json();
        if (!response.ok) {
          setIsApplePaying(false);
          errorNotification(data?.message);
          throw new Error(response);
        }
        const d = data?.data || data;
        window.location.href = d.url;

        setIsApplePaying(false);
      } catch (error) {
        setIsApplePaying(false);
      }
    }
  };

  const handleExpiryChange = (e) => {
    let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    let month = "";
    let year = "";

    if (value.length >= 2) {
      month = value.slice(0, 2);
    }
    if (value.length >= 4) {
      year = value.slice(2, 4);
    }

    // Format as MM/YY while typing
    if (value.length > 2) {
      value = `${month}/${year}`;
    }

    // Update the individual fields for month and year
    setValue("month", month);
    setValue("year", year);
  };

  const values = watch();
  return (
    <Fragment>
      <div className="main">
        <div className="box card-div">
          <div className="inner">
            <div className="info-div-sm">
              {showMerchantDetails && (
                <div>
                  <p style={{ fontWeight: 600, fontSize: 20, margin: 0 }}>
                    {featureData.merchant_name}
                  </p>
                  <div className="info-item">
                    <span>Invoice:</span>
                    <p style={{ textAlign: "right", margin: 0 }}>
                      {featureData.merchant_invoice_id}
                    </p>
                  </div>
                  <div className="info-item" style={{ marginTop: 5 }}>
                    <span>Due date:</span>
                    <span>
                      {moment(featureData.expiry_time).format("MMMM Do YYYY")}
                    </span>
                  </div>
                  <div className="info-item" style={{ marginTop: 5 }}>
                    <span>Invoice amount:</span>
                    <span>
                      {featureData.amount} {featureData.currency}
                    </span>
                  </div>
                  <p
                    style={{
                      fontWeight: 600,
                      fontSize: 18,
                      marginTop: 30,
                      marginBottom: 0,
                    }}>
                    {featureData.customer_name}
                  </p>
                  <p className="info-text">
                    <img
                      src={process.env.PUBLIC_URL + "/assets/pngs/email.png"}
                      alt="email"
                      style={{ height: 20 }}
                    />{" "}
                    {featureData.customer_email}
                  </p>
                  <p className="info-text">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/pngs/phonebook.png"
                      }
                      alt="phonebook"
                      style={{ height: 20 }}
                    />{" "}
                    {featureData.phone_number}
                  </p>
                  <p className="info-text">
                    <img
                      src={process.env.PUBLIC_URL + "/assets/pngs/location.png"}
                      alt="location"
                      style={{ height: 20 }}
                    />{" "}
                    {featureData.customer_address}
                  </p>
                </div>
              )}

              <button
                onClick={() => setShowMerchantDetails(!showMerchantDetails)}
                className="info-btn">
                View invoice and more info
                <img
                  src={
                    showMerchantDetails
                      ? process.env.PUBLIC_URL + "/assets/pngs/chevron-up.png"
                      : process.env.PUBLIC_URL + "/assets/pngs/chevron-down.png"
                  }
                  alt="chevron"
                  style={{ width: 20 }}
                />
              </button>
            </div>

            <p
              style={{
                color: "#8C9199",
                fontWeight: "600",
                margin: "0 auto",
              }}>
              PAYMENT AMOUNT
            </p>
            <p style={{ fontSize: 30, marginTop: 0, fontWeight: 600 }}>
              {featureData.amount} {featureData.currency}
            </p>

            <div className="menu-list">
              <button onClick={() => setMode("debit")}>
                <div className="menu-item">
                  <div
                    className={`${
                      mode === "debit" ? "selected" : ""
                    } border`}></div>
                  <div className="image">
                    <img src={debit} alt="Debit Card Icon" />
                  </div>
                </div>
                <span
                  style={{
                    fontSize: 18,
                    fontWeight: 600,
                    color: mode === "debit" ? "#000" : "#8C9199",
                  }}>
                  Debit
                </span>
              </button>
              <button onClick={() => setMode("credit")}>
                <div className="menu-item">
                  <div
                    className={`${
                      mode === "credit" ? "selected" : ""
                    } border`}></div>
                  <div className="image">
                    <img src={credit} alt="Credit Card Icon" />
                  </div>
                </div>
                <span
                  style={{
                    fontSize: 18,
                    fontWeight: 600,
                    color: mode === "credit" ? "#000" : "#8C9199",
                  }}>
                  Credit
                </span>
              </button>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-row">
                <div className="input input-lg">
                  <label htmlFor="cardNumber">
                    <span>*</span>Card Number
                  </label>
                  <input
                    name="cardnumber"
                    type="text"
                    id="cardNumber"
                    placeholder="1234 1234 1234 1234"
                    {...register("cardnumber", {
                      onChange: (e) => {
                        handleChange(e, "cardnumber");
                      },
                    })}
                    onFocus={handleFocused}
                  />
                  {errors?.cardnumber?.message && (
                    <p className="error-field">{errors?.cardnumber?.message}</p>
                  )}
                </div>

                <div className="input-group-sm">
                  <div className="input input-sm">
                    <label htmlFor="cardExpiry">
                      <span>*</span>Expiry Date
                    </label>
                    <input
                      name="expiry"
                      type="text"
                      id="cardExpiry"
                      placeholder="MM/YY"
                      maxLength="5"
                      {...register("expiry", {
                        onChange: handleExpiryChange,
                        pattern: {
                          value: /^(0[1-9]|1[0-2])\/?([0-9]{2})$/,
                          message: "Invalid expiry date format",
                        },
                      })}
                    />
                    {errors?.expiry?.message && (
                      <p className="error-field">{errors?.expiry?.message}</p>
                    )}
                  </div>

                  <div className="input input-sm">
                    <label htmlFor="cardCvc">
                      <span>*</span>CVC
                    </label>
                    <input
                      name="cvc"
                      type="text"
                      id="cardCvc"
                      maxLength={"3"}
                      placeholder="123"
                      {...register("cvv", {
                        onChange: (e) => {
                          handleChange(e, "cvv", 3);
                        },
                      })}
                      onFocus={handleFocused}
                    />
                    {errors?.cvv?.message && (
                      <p className="error-field">{errors?.cvv?.message}</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div className="input input-lg">
                  <label htmlFor="cardName">
                    <span>*</span>Card Name
                  </label>
                  <input
                    name="cardname"
                    type="text"
                    id="cardName"
                    placeholder="John Wick"
                    {...register("cardname")}
                    onFocus={handleFocused}
                  />
                  {errors?.cardname?.message && (
                    <p className="error-field">{errors?.cardname?.message}</p>
                  )}
                </div>

                <div className="input-group-sm">
                  <div className="input input-sm">
                    <label htmlFor="country">Country:</label>
                    <select id="country" {...register("country")}>
                      <option value={""}>{"select"}</option>
                      {countryList.map((country, index) => (
                        <option
                          key={`country_${index + 1}`}
                          value={country.value}>
                          {country.label}
                        </option>
                      ))}
                    </select>
                    {errors?.country?.message && (
                      <p className="error-field">{errors?.country?.message}</p>
                    )}
                  </div>

                  <div className="input input-sm">
                    <label htmlFor="postalCode">ZIP Code</label>
                    <input
                      name="postalcode"
                      type="text"
                      id="postalCode"
                      placeholder="Postal Code"
                      {...register("postalcode")}
                    />
                    {errors?.postalcode?.message && (
                      <p className="error-field">
                        {errors?.postalcode?.message}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="form-row-lg">
                <div className="input input-sm">
                  <label htmlFor="city">City</label>
                  <input
                    name="city"
                    type="text"
                    id="city"
                    placeholder="City"
                    {...register("city")}
                  />
                  {errors?.city?.message && (
                    <p className="error-field">{errors?.city?.message}</p>
                  )}
                </div>
                <div className="input input-sm">
                  <label htmlFor="state">State</label>
                  <input
                    name="state"
                    type="text"
                    id="state"
                    placeholder="State"
                    {...register("state")}
                  />
                  {errors?.state?.message && (
                    <p className="error-field">{errors?.state?.message}</p>
                  )}
                </div>
              </div>

              <p style={{ color: "#8C9199", fontSize: 13 }}>
                By selecting <b>Pay</b>, you accept the{" "}
                <a
                  href="https://ibanera.com/agreements/Terms-Of-Service_NFT_v2.pdf"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "underline", color: "#8C9199" }}>
                  Terms of Service
                </a>
                . Our Global{" "}
                <a
                  href="https://www.ibanera.com/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "underline", color: "#8C9199" }}>
                  Privacy Statement
                </a>{" "}
                will apply to any personal information you provide us. You also
                allow us to charge{" "}
                <b>
                  {featureData.amount} {featureData.currency}
                </b>{" "}
                to your card on {moment().format("MMMM Do YYYY")}
              </p>

              <button
                className="checkoutBtn"
                disabled={isSubmitting}
                onClick={() => {
                  setValue("is_google_pay", false);
                  setValue("is_apple_pay", false);
                }}
                style={{ pointerEvents: isSubmitting ? "none" : "auto" }}>
                {isSubmitting ? "Paying..." : "Pay"} {featureData.amount}{" "}
                {featureData.currency}
              </button>

              <div className="paymentMethodDiv">
                <GooglePay
                  featureData={featureData}
                  isLoading={isGooglePaying}
                  setIsLoading={setIsGooglePaying}
                  handleGooglePayClick={handleGooglePay}
                  is_google_pay={featureData.is_google_pay}
                  values={{}}
                />
                <ApplePay
                  featureData={featureData}
                  isLoading={isApplePaying}
                  setIsLoading={setIsApplePaying}
                  handleApplePayClick={handleApplePay}
                  is_apple_pay={featureData.is_apple_pay}
                  values={{}}
                  is_support_3d={featureData.is_support_3d}
                />
              </div>

              <p
                style={{
                  color: "#8C9199",
                  fontSize: 13,
                  textAlign: "center",
                  marginTop: 30,
                }}>
                2024 © Ibanera LLC. All rights reserved.
              </p>
            </form>
          </div>
        </div>
        <div className="info-div">
          <div className="box">
            <div className="inner">
              <p style={{ fontWeight: 600, fontSize: 20, marginTop: 0 }}>
                {featureData.merchant_name}
              </p>
              <div className="info-item">
                <span>Invoice:</span>
                <p style={{ textAlign: "right", margin: 0 }}>
                  {featureData.merchant_invoice_id}
                </p>
              </div>
              <div></div>
              <div className="info-item" style={{ marginTop: 10 }}>
                <span>Due date:</span>
                <span>
                  {moment(featureData.expiry_time).format("MMMM Do YYYY")}
                </span>
              </div>
              <div className="info-item" style={{ marginTop: 10 }}>
                <span>Invoice amount:</span>
                <span>
                  {featureData.amount} {featureData.currency}
                </span>
              </div>
            </div>
          </div>
          <div className="box" style={{ paddingBottom: 10 }}>
            <div className="inner">
              <p style={{ fontWeight: 600, fontSize: 18, margin: 0 }}>
                {featureData.customer_name}
              </p>
              <p style={{ marginTop: 0 }}>
                Email: {featureData.customer_email}
              </p>
              <button
                onClick={() => setShowMerchantDetails(!showMerchantDetails)}
                style={{
                  border: 0,
                  background: "transparent",
                  width: "100%",
                  cursor: "pointer",
                }}>
                <img
                  src={
                    showMerchantDetails
                      ? process.env.PUBLIC_URL + "/assets/pngs/chevron-up.png"
                      : process.env.PUBLIC_URL + "/assets/pngs/chevron-down.png"
                  }
                  alt="chevron"
                  style={{ width: 20 }}
                />
              </button>

              {showMerchantDetails && (
                <div>
                  <p
                    style={{
                      marginTop: 0,
                      display: "flex",
                      alignItems: "center",
                      gap: 5,
                    }}>
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/pngs/phonebook.png"
                      }
                      alt="phonebook"
                      style={{ height: 30 }}
                    />{" "}
                    {featureData.phone_number}
                  </p>
                  <p
                    style={{
                      marginTop: 0,
                      display: "flex",
                      alignItems: "center",
                      gap: 5,
                    }}>
                    <img
                      src={process.env.PUBLIC_URL + "/assets/pngs/location.png"}
                      alt="location"
                      style={{ height: 30 }}
                    />{" "}
                    {featureData.customer_address}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* <div className="checkout-form">
        <form className="wrapper" onSubmit={handleSubmit(onSubmit)}>
          <div className="upper-wrapper">
            <DebitCard values={values} focused={focused} />
            <div className="fields-wrapper">
              <div className="input">
                <label htmlFor="cardName">
                  <span>*</span>Card Name
                </label>
                <input
                  name="cardname"
                  type="text"
                  id="cardName"
                  placeholder="John Wick"
                  {...register("cardname")}
                  onFocus={handleFocused}
                />
                {errors?.cardname?.message && (
                  <p className="error-field">{errors?.cardname?.message}</p>
                )}
              </div>
              <div className="input">
                <label htmlFor="cardNumber">
                  <span>*</span>Card Number
                </label>
                <input
                  name="cardnumber"
                  type="text"
                  id="cardNumber"
                  placeholder="1234 1234 1234 1234"
                  {...register("cardnumber", {
                    onChange: (e) => {
                      handleChange(e, "cardnumber");
                    },
                  })}
                  onFocus={handleFocused}
                />
                {errors?.cardnumber?.message && (
                  <p className="error-field">{errors?.cardnumber?.message}</p>
                )}
              </div>
              <div className="flex-3">
                <div className="input input-sm">
                  <label htmlFor="cardExpiry">
                    <span>*</span>Expiry Date
                  </label>
                  <input
                    name="expiry"
                    type="text"
                    id="cardExpiry"
                    placeholder="MM/YY"
                    maxLength="5"
                    {...register("expiry", {
                      onChange: handleExpiryChange,
                      pattern: {
                        value: /^(0[1-9]|1[0-2])\/?([0-9]{2})$/,
                        message: "Invalid expiry date format",
                      },
                    })}
                  />
                  {errors?.expiry?.message && (
                    <p className="error-field">{errors?.expiry?.message}</p>
                  )}
                </div>

                <div className="input">
                  <label htmlFor="cardExpiryMonth">
                    <span>*</span>Expiry Month
                  </label>
                  <input
                    name="month"
                    type="text"
                    id="cardExpiryMonth"
                    placeholder="MM"
                    maxLength={"2"}
                    {...register("month", {
                      onChange: (e) => {
                        handleChange(e, "month", 2);
                      },
                    })}
                    onFocus={handleFocused}
                  />
                  {errors?.month?.message && (
                    <p className="error-field">{errors?.month?.message}</p>
                  )}
                </div>
                <div className="input">
                  <label htmlFor="cardExpiryYear">
                    <span>*</span>Expiry Year
                  </label>
                  <input
                    name="year"
                    type="text"
                    id="cardExpiryYear"
                    placeholder="YY"
                    maxLength={"2"}
                    {...register("year", {
                      onChange: (e) => {
                        handleChange(e, "year", 2);
                      },
                    })}
                    onFocus={handleFocused}
                  />
                  {errors?.year?.message && (
                    <p className="error-field">{errors?.year?.message}</p>
                  )}
                </div>
                <div className="input">
                  <label htmlFor="cardCvc">
                    <span>*</span>CVC
                  </label>
                  <input
                    name="cvc"
                    type="text"
                    id="cardCvc"
                    maxLength={"3"}
                    placeholder="123"
                    {...register("cvv", {
                      onChange: (e) => {
                        handleChange(e, "cvv", 3);
                      },
                    })}
                    onFocus={handleFocused}
                  />
                  {errors?.cvv?.message && (
                    <p className="error-field">{errors?.cvv?.message}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-wrapper">
            <div className="flex-2">
              <div className="input">
                <label htmlFor="email">Email</label>
                <input
                  name="email"
                  type="text"
                  id="email"
                  placeholder="abc@gmail.com"
                  {...register("email")}
                />
                {errors?.email?.message && (
                  <p className="error-field">{errors?.email?.message}</p>
                )}
              </div>
              <div className="input">
                <label htmlFor="phone">Phone</label>
                <input
                  name="phone"
                  type="tel"
                  id="phone"
                  placeholder="123123123"
                  {...register("phone")}
                />
                {errors?.phone?.message && (
                  <p className="error-field">{errors?.phone?.message}</p>
                )}
              </div>
            </div>
            <div className="flex-2">
              <div className="input">
                <label htmlFor="amount">Amount</label>
                <input
                  name="state"
                  type="text"
                  id="amount"
                  disabled={true}
                  readOnly={true}
                  value={featureData?.amount}
                />
              </div>
              <div className="input">
                <label htmlFor="country">Choose a country:</label>
                <select id="country" {...register("country")}>
                  <option value={""}>{"select"}</option>
                  {countryList.map((country, index) => (
                    <option key={`country_${index + 1}`} value={country.value}>
                      {country.label}
                    </option>
                  ))}
                </select>
                {errors?.country?.message && (
                  <p className="error-field">{errors?.country?.message}</p>
                )}
              </div>
            </div>
            <div className="flex-3">
              <div className="input">
                <label htmlFor="state">State</label>
                <input
                  name="state"
                  type="text"
                  id="state"
                  placeholder="State"
                  {...register("state")}
                />
                {errors?.state?.message && (
                  <p className="error-field">{errors?.state?.message}</p>
                )}
              </div>
              <div className="input">
                <label htmlFor="city">City</label>
                <input
                  name="city"
                  type="text"
                  id="city"
                  placeholder="City"
                  {...register("city")}
                />
                {errors?.city?.message && (
                  <p className="error-field">{errors?.city?.message}</p>
                )}
              </div>
              <div className="input">
                <label htmlFor="postalCode">Postal Code</label>
                <input
                  name="postalcode"
                  type="text"
                  id="postalCode"
                  placeholder="Postal Code"
                  {...register("postalcode")}
                />
                {errors?.postalcode?.message && (
                  <p className="error-field">{errors?.postalcode?.message}</p>
                )}
              </div>
            </div>
            <div className="input">
              <label htmlFor="address">Address</label>
              <input
                name="address"
                type="text"
                id="address"
                placeholder="42 wall street"
                {...register("address")}
              />
              {errors?.address?.message && (
                <p className="error-field">{errors?.address?.message}</p>
              )}
            </div>
          </div>
          <button
            className="checkoutBtn"
            disabled={isSubmitting}
            onClick={() => {
              setValue("is_google_pay", false);
              setValue("is_apple_pay", false);
            }}
            style={{ pointerEvents: isSubmitting ? "none" : "auto" }}>
            {isSubmitting ? "Submitting..." : "Submit"}
          </button>
          <div className="paymentMethodDiv">
            <GooglePay
              featureData={featureData}
              isLoading={isGooglePaying}
              setIsLoading={setIsGooglePaying}
              handleGooglePayClick={handleGooglePay}
              is_google_pay={featureData.is_google_pay}
              values={{}}
            />
            <ApplePay
              featureData={featureData}
              isLoading={isApplePaying}
              setIsLoading={setIsApplePaying}
              handleApplePayClick={handleApplePay}
              is_apple_pay={featureData.is_apple_pay}
              values={{}}
              is_support_3d={featureData.is_support_3d}
            />
          </div>
        </form>
      </div> */}
    </Fragment>
  );
};

export default Invoice;
